import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import { formatPrice } from "../utils/format-price"

// Importing styles
import "./styles/product-card.scss"

// Importing Bootstrap components
import { Button, Card } from "react-bootstrap"

export function ProductCard({ product, eager }) {
  const {
    title,
    priceRangeV2,
    handle,
    images: [firstImage],
    storefrontImages,
  } = product

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )

  let storefrontImageData = {}
  if (storefrontImages) {
    const storefrontImage = storefrontImages.edges[0].node
    try {
      storefrontImageData = getShopifyImage({
        image: storefrontImage,
        layout: "fixed",
        width: 200,
        height: 200,
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Card className="card">
      <Link to={handle} aria-label={`View ${title} product page`}>
        {}
        <GatsbyImage
          alt={firstImage?.altText ?? title}
          image={firstImage?.gatsbyImageData ?? storefrontImageData}
          loading={eager ? "eager" : "lazy"}
        />
        <div className="price shout">{price}</div>
        <Card.Body>
          <h2 className="talk">{title}</h2>
          <div className="cta talk">View Product &rarr;</div>
        </Card.Body>
      </Link>
    </Card>
  )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    handle
    id
    title
    images {
      id
      altText
      gatsbyImageData(aspectRatio: 1, width: 640)
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }
  }
`
