import * as React from "react"
import { useState, useEffect } from "react"

// Importing components
import { Header } from "../components/header"
import { Footer } from "../components/footer"
import { Seo } from "../components/seo"

// Import base styles
import "bootstrap/dist/css/bootstrap.min.css"
import "../base.scss"
import "../assets/fonts/fonts.css"
import "../variables.scss"
// Wordpress
import "../post-content.scss"
import "../wpStyles.css"

// Importing layout styles
import "./styles/layout.scss"

export function Main({ children }) {
  return (
    <div id="bodyDiv">
      <Seo />
      <Header />
      {children}
      <Footer />
    </div>
  )
}
