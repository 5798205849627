import * as React from "react"
import { graphql } from "gatsby"
import slugify from "@sindresorhus/slugify"
import debounce from "debounce"

// Importing layout
import { Main } from "../layouts/main"

// Importing components
import { ProductCard } from "../components/product-card"
import { getValuesFromQueryString, useProductSearch } from "../utils/hooks"
import { getCurrencySymbol } from "../utils/format-price"
import { Spinner } from "../components/progress"
import { Filters } from "../components/filters"
import { SearchProvider } from "../context/search-provider"

// Importing styles
import "./styles/search-page.scss"

// Importing icons
import {
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaTimes,
} from "react-icons/fa"

// Importing Bootstrap components
import { Col, Container, Row } from "react-bootstrap"

export const query = graphql`
  query {
    meta: allShopifyProduct {
      tags: distinct(field: tags)
    }
    products: allShopifyProduct(limit: 24, sort: { fields: title }) {
      edges {
        node {
          title
          handle
          priceRangeV2 {
            minVariantPrice {
              currencyCode
              amount
            }
            maxVariantPrice {
              currencyCode
              amount
            }
          }
          id
          images {
            gatsbyImageData(aspectRatio: 1, width: 200, layout: FIXED)
          }
        }
      }
    }
  }
`

function SearchPage({
  data: {
    meta: { tags },
    products,
  },
  location,
}) {
  // These default values come from the page query string
  const queryParams = getValuesFromQueryString(location.search)
  const [filters, setFilters] = React.useState(queryParams)
  const [sortKey, setSortKey] = React.useState(queryParams.sortKey)
  // We clear the hash when searching, we want to make sure the next page will be fetched due the #more hash.
  const shouldLoadNextPage = React.useRef(false)

  // This modal is only used on mobile
  const [showModal, setShowModal] = React.useState(false)

  const {
    data,
    isFetching,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useProductSearch(
    filters,
    {
      allTags: tags,
    },
    sortKey,
    false,
    24 // Products per page
  )

  // If we're using the default filters, use the products from the Gatsby data layer.
  // Otherwise, use the data from search.
  const isDefault = !data
  const productList = (isDefault ? products.edges : data?.products?.edges) ?? []

  // Scroll up when navigating
  React.useEffect(() => {
    if (!showModal) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })
    }
  }, [productList, showModal])

  // Stop page from scrolling when modal is visible
  React.useEffect(() => {
    if (showModal) {
      document.documentElement.style.overflow = "hidden"
    } else {
      document.documentElement.style.overflow = ""
    }
  }, [showModal])

  // Automatically load the next page if "#more" is in the URL
  React.useEffect(() => {
    if (location.hash === "#more") {
      // save state so we can fetch it when the first page got fetched to retrieve the cursor
      shouldLoadNextPage.current = true
    }

    if (shouldLoadNextPage.current) {
      if (hasNextPage) {
        fetchNextPage()
      }

      shouldLoadNextPage.current = false
    }
  }, [location.hash])

  const currencyCode = getCurrencySymbol(
    products?.[0]?.node?.priceRangeV2?.minVariantPrice?.currencyCode
  )

  return (
    <Main>
      <div className="search">
        <div className="search-bar">
          <Container>
            <Row>
              <Col xs={8} md={10}>
                <SearchBar defaultTerm={filters.term} setFilters={setFilters} />
              </Col>
              <Col xs={4} md={2}>
                <div className="search-sort">
                  <label>
                    <span>Sort by:</span>
                    <select
                      value={sortKey}
                      // eslint-disable-next-line
                      onChange={(e) => setSortKey(e.target.value)}
                    >
                      <option value="RELEVANCE">Relevance</option>
                      <option value="PRICE">Price</option>
                      <option value="TITLE">Title</option>
                      <option value="CREATED_AT">New items</option>
                      <option value="BEST_SELLING">Trending</option>
                    </select>
                  </label>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="search-results">
          <Row>
            {!isFetching &&
              productList.map(({ node }, index) => (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}
                  className="product-card"
                  key={node.id}
                >
                  <ProductCard
                    eager={index === 0}
                    product={{
                      title: node.title,
                      priceRangeV2: node.priceRangeV2,
                      handle: `/products/${node.handle}`,
                      // The search API and Gatsby data layer have slightly different images available.
                      images: isDefault ? node.images : [],
                      storefrontImages: !isDefault && node.images,
                    }}
                  />
                </Col>
              ))}
          </Row>
          {hasPreviousPage || hasNextPage ? (
            <Row className="search-pagination">
              <Pagination
                previousPage={fetchPreviousPage}
                hasPreviousPage={hasPreviousPage}
                nextPage={fetchNextPage}
                hasNextPage={hasNextPage}
              />
            </Row>
          ) : undefined}
        </Container>
      </div>
    </Main>
  )
}

function SearchBar({ defaultTerm, setFilters }) {
  const [term, setTerm] = React.useState(defaultTerm)
  const debouncedSetFilters = React.useCallback(
    debounce((value) => {
      setFilters((filters) => ({ ...filters, term: value }))
    }, 200),
    [setFilters]
  )

  return (
    <form onSubmit={(e) => e.preventDefault()} className="search-form">
      <div className="search-icon">
        <FaSearch />
      </div>
      <input
        type="text"
        value={term}
        onChange={(e) => {
          setTerm(e.target.value)
          debouncedSetFilters(e.target.value)
        }}
        placeholder="Search..."
      />
      {term ? (
        <button
          className="clear-search"
          type="reset"
          onClick={() => {
            setTerm("")
            setFilters((filters) => ({ ...filters, term: "" }))
          }}
          aria-label="Clear search query"
        >
          <FaTimes />
        </button>
      ) : undefined}
    </form>
  )
}
/**
 * Shopify only supports next & previous navigation
 */
function Pagination({ previousPage, hasPreviousPage, nextPage, hasNextPage }) {
  return (
    <nav className="pagination">
      <button
        disabled={!hasPreviousPage}
        onClick={previousPage}
        aria-label="Previous page"
      >
        <FaChevronLeft />
      </button>
      <button disabled={!hasNextPage} onClick={nextPage} aria-label="Next page">
        <FaChevronRight />
      </button>
    </nav>
  )
}

export default function SearchPageTemplate(props) {
  return (
    <SearchProvider>
      <SearchPage {...props} />
    </SearchProvider>
  )
}
